import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, catchError, filter, map, mergeMap, pairwise, tap, throwError } from 'rxjs';
import { getSession } from './auth/store/auth.actions';
import { IconRegistryService } from './core/icon-registry/icon-registry.service';
import { DialogService } from './core/services/dialog.service';
import { GlobalService } from './core/services/global.service';
import { ThemeService } from './core/services/theme.service';
import { AppState } from './store/app.reducers';

export const APP_VERSION = '1.6.35';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ZEVA';

  private readonly subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly store: Store<AppState>,
    private readonly global: GlobalService,
    private readonly themeService: ThemeService,
    private iconRegistry: IconRegistryService,
    private dialogService: DialogService,
    private elem: ElementRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getSession());

    // Initialize the dialog service
    this.dialogService.init();

    /**
     * Common Angular Router events pipe that can be shared for different task
     * that base off of similar origin
     */
    const routerCommonPipe$ = this.router.events.pipe(
      // accept only emissions that happen at the end of navigation sequence
      filter(event => event instanceof NavigationEnd),
      // eslint-disable-next-line max-len
      // pass on the currently active route (Angular stores reference to the route in ActivatedRoute injectable)
      map(() => this.activatedRoute),
      map(route => {
        // loop through route children to find last activated route
        while (route.firstChild) route = route.firstChild;
        return route; // last activated route
      }),
      filter(route => route.outlet === 'primary')
    );

    /** extract router event with currently opened page's data from the route setup */
    const title$ = routerCommonPipe$.pipe(
      // filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data), // pull path's data containing 'title'
      tap(event => {
        // set returned path's title
        // eslint-disable-next-line prettier/prettier
        this.title = `${event['title'] as string} - ZEVA`;
        this.titleService.setTitle(this.title);
      })
    );
    this.subscription.add(title$.subscribe());

    /** collect and store immediate navigation to retain previous and current routes */
    const navigation$ = this.router.events.pipe(
      filter(evt => evt instanceof RoutesRecognized),
      pairwise(),
      tap(events => {
        const [prev, next] = [events[0], events[1]] as [RoutesRecognized, RoutesRecognized];

        // omit same or similar links being recorded in succession
        if (next.url.includes(prev.url)) return;

        this.global.navigationState.previousRoute.next(prev);
        this.global.navigationState.currentRoute.next(next);
      })
    );
    this.subscription.add(navigation$.subscribe());
    const theme$ = this.themeService.getTheme().pipe(
      catchError(error => {
        this.themeService.setAuto();
        return throwError(() => error);
      })
    );
    this.subscription.add(theme$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
